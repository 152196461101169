<div class="grid mb-2" style="background-color: #fff100;">
    <div class="col-12 sm:col-6 p-5">
        <img src="../../../../assets/Logos_SinFondo_ElPasito_830x191.png" alt="Logo">
    </div>
</div>
<div class="grid mt-2 ml-2 mr-2 mb-4">
    <div class="col-12 sm:col-6 p-5">
        <mat-card>
            <h1>Lo sentimos, su solicitud de crédito continua en proceso, sin embargo no es posible mostrar alguna actualización en este momento,
                en caso de dudas favor de comunicarse con su vendedor ó a consentilinea al 6144428202
            </h1>
        </mat-card>
    </div>
</div>