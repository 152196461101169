

export const environment = {

    environmentName: 'qa',
    production: false,
    urlProxy: 'https://rogoproxy.xyz/',
    baseURL: 'https://rogoproxy.xyz/http://microsqa.gruporoga.com:2023/rogaservices/',
    userRoga: {
        userId: 4834,
        userPass: 121314,
        userProfile: 1,
        macAddress: '00-14-22-01-23-45',
        appId: 22,
        idSucursal: 1,
      },
      urlLogin:
      'https://rogoproxy.xyz/http://microsqa.gruporoga.com:2026/security/login',
    version: '1.0.0',
}
