import { Component } from '@angular/core';
import { ServiceAuthService } from './credito/services/service-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'LandingEstatusCredito';

  constructor( public serviceAuth: ServiceAuthService) {
  }

  onClickReintentar(): void {
    this.serviceAuth.initConfig();
  }

}
