import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';

// cambiar la localidad de la app
import localeEs from '@angular/common/locales/es-MX';
// Registra los datos globales que serán utilizados internamente por Angular.
import { registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { WebcamModule } from 'ngx-webcam';
import { CameraComponent } from './credito/components/camera/camera.component';
registerLocaleData(localeEs);





@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    WebcamModule
  ],
  providers: [
    {
    provide: LOCALE_ID, useValue: 'es-MX'
    },
   {
    provide: MAT_DATE_LOCALE, useValue: 'es-MX'
   }

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
