import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs';
import { environment } from 'src/environments/environment';

export let tokenRoga: string = '';
export const TIMEOUT = 60000;

const headers = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class ServiceAuthService {

  confCargadas: boolean = false;
  errorConf = false;

  hideNave = false;
  hideFooter = false;
  // versionApp = environment.version + ' ' + environment.environmenName;

  constructor(private http: HttpClient) {
    this.initConfig();
  }


  async initConfig() {
    try {
      this.errorConf = false;
      this.confCargadas = false;
     
      // this.conf = this.getConfig();
      tokenRoga = await this.setLoginUserRoga();
      // console.log(tokenRoga);

      this.confCargadas = true;
    } catch (error) {
      this.confCargadas = false;
      this.errorConf = true;
    }
  }

  private setLoginUserRoga() {
    // console.log(environment.userRoga);

    return new Promise<any>((resolve, reject) => {
      return this.http
        .post(
          environment.urlLogin,
          JSON.stringify(environment.userRoga),
          headers
        )
        .pipe(timeout(TIMEOUT))
        .subscribe(
          (authUser: any) => resolve(authUser.token),
          (err) => {
            console.log(err);

            reject(err);
          }
        );
    });
  }


}
