<div *ngIf="serviceAuth.confCargadas && !serviceAuth.errorConf">
    <router-outlet></router-outlet>
</div>
<div class="container " *ngIf="!serviceAuth.confCargadas">
    <div class="row justify-content-center align-items-center" style="height: 100vh;">
        <div *ngIf="!serviceAuth.confCargadas && !serviceAuth.errorConf">
            <div class="col align-self-center">
                <div class="spinner-grow text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

        <div *ngIf="!serviceAuth.confCargadas && serviceAuth.errorConf">
            <div class="col align-self-center text-center ">
                <p>Ha ocurrido un error, por favor reinténtalo.</p>
                <button type="button" class="btn btn-primary" (click)="onClickReintentar()"> <i class="fas fa-sync-alt"></i> Reintentar</button>
            </div>
        </div>
    </div>
</div>